<template>
  <div class="banner_vbg">
    <div class="banner_content">
      <div class="banner_width">
    <div class="new_pwdless_main_heading">
      Off to a great start! Keep going.
    </div>
      <span style="float: right;">{{ completedTasks }}/{{ totalMandatoryTasks }}  tasks completed</span>
    <div class="progress_container">
      <div class="progress_bar" 
        :style="{width:progress + '%'}">
        <!-- {{this.progress.toFixed(0) + '%'}} -->
      </div>
    </div>
  </div>

    <div>
      <button class="banner_btn" @click="goBack">Complete your onboarding</button>
    </div>
  
  </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  //  resp : {
  //   "serviceStatus": {
  //     "success": true,
  //     "message": "SUCCESS",
  //     "statusCode": 200
  //   },
  //   "welcomeHubDetails": [
  //     {
  //       "taskId": "1",
  //       "taskName": "Register for My Business",
  //       "totalCompletionStep": "1",
  //       "flexField1": "Y",
  //       "flexField2": "FLEX2",
  //       "createdDate": "2024-12-03T10:09:58.000+00:00",
  //       "modifiedDate": "2024-12-03T10:09:58.000+00:00",
  //       "completed": true,
  //       "mandatory": true,
  //       "isNewTab": "N",
  //       "lastLoginDate": "2024-12-03T00:00:00.000+00:00",
  //       "loginCounter": 1
  //     },
  //     {
  //       "taskId": "18",
  //       "taskName": "Verify your contact information",
  //       "taskDescription": "Confirm your contact details to avoid missing out on important updates.",
  //       "totalCompletionStep": "1",
  //       "flexField1": "Y",
  //       "taskNavigationUrl": "/mbt/secure/index?appName=usermgmt&navtrk=globalnav:manage:companyusers#/ebilling/company-users",
  //       "createdDate": "2024-12-06T09:22:37.000+00:00",
  //       "modifiedDate": "2024-12-06T09:22:37.000+00:00",
  //       "completed": true,
  //       "mandatory": true,
  //       "isNewTab": "N",
  //       "lastLoginDate": "2024-12-06T09:22:37.000+00:00",
  //       "loginCounter": 1
  //     },
  //       ],
  //   "totalMandatoryTasks": 7,// sample
  //   "completedTasks": 4, // sample
  //   "newUser": true, // TRUE - WHEN ECPD IS WITHIN THE FIRST 90 DAYS OF CREATION
  //   "busInternet5GEligible": false,
  //   "showWelcomeHubPage": true, // TRUE - SHOW WELCOME HUB PAGE ON LOGIN - (WHEN THE USER IS LOGGING IN TO SYSTEM, WITHIN THE FIRST 3 DAYS). IF FALSE - SHOW HOMEPAGE
  //   "oneTalkEnabled": false
  // },

  name: 'WelcomeHubBanner',
  data() {
    return {
      progress: (0 / 8) * 100, // Calculates the progress percentage
      totalMandatoryTasks : 0,
      completedTasks:0

    };
  },
  mounted() {

  const payload =  {
  "ecpdId": "8341818", // mandatory
  "userId": "8341818_SMB_QA2_SPOC", // mandatory
  "clientId": "MVB", // mandatory
  "ecpdProfileCreationDate": "12/06/2024", // mandatory
  "role": "PrimaryContact" // mandatory
}
    this.updateProgressDataAction(payload)
  },
  methods:{
    goBack(){
      window.history.back()
    },
    getProgressValue: function(totalMandatoryTasks,completedTasks ){
      this.progress = completedTasks/totalMandatoryTasks * 100;
    },
    ...mapActions('profile', [
      'updateProgressDataAction'
    ]),
  },
  computed: {
    ...mapGetters("profile", [
      "errorMsg",
      "showerrorMsg",
      "progressData"
    ]),
  },
  watch: {
    progressData: function (newVal) {
      this.getProgressValue(newVal.totalMandatoryTasks, newVal.completedTasks)
      this.totalMandatoryTasks = newVal.totalMandatoryTasks
      this.completedTasks = newVal.completedTasks
    },
  },
  
};
</script>
<style scoped>
.banner_vbg{
  width: 100%;
  background-color: #f3ede0;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.banner_content{
    height: auto;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
}
.banner_btn{
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'VzNHGeTX-bold11';
  padding: 12px 12px;
  color: #000000;
}
.progress_container{
  width: 100%;
  /* max-width: 600px; */
  background-color: #E0E0E0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-top: 10px;
}
.progress_bar{
  background-color: #4CAF50;
  color: #FFFFFF;
  text-align: center;
  line-height: 30px;
  height: 10px;
  width: 0;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
}
.banner_width{
  width: 80%;
}
</style>